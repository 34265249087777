<script>
    // import Button from '@smui/button';
    // import Textfield from '@smui/textfield';
    // import HelperText from '@smui/textfield/helper-text';
    import Snackbar from '@smui/snackbar';
    import Fab from '@smui/fab';
    import {Label, Icon} from '@smui/common';

    let mySnackbar;

</script>

<h1>Servisop</h1>

<!-- <Button on:click={() => alert('Clicked!')}>Just a Button</Button>
<Button variant="raised"><Label>Raised Button, Using a Label</Label></Button>
<Button some-arbitrary-prop="placed on the actual button">Button</Button> -->

<Fab on:click={() => mySnackbar.open()} extended>
  <Icon class="material-icons" style="margin-right: 12px;">favorite</Icon>
  <Label>Me gusta!</Label>
</Fab>

<Snackbar bind:this={mySnackbar}>
  <Label>Gracias!</Label>
</Snackbar>

<!-- <Textfield
  bind:value={superText}
  label="Super Text"
  input$aria-controls="super-helper"
  input$aria-describedby="super-helper"
/>
<HelperText id="super-helper">What you put in this box will become super! {superText}</HelperText> -->